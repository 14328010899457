.DateInput_input__focused {
  /* outline: 0; */
  background: #fff;
  border: 2px solid #3f51b5;
  border-top: 2px solid #3f51b5;
  border-right: 2px solid #3f51b5;
  border-bottom: 2px solid #3f51b5;
  border-left: 2px solid #3f51b5;
  padding: 8px 0px 9px 0px;
  border-radius: 5px;
}

.DateRangePickerInput__withBorder {
  border-radius: 5px;
  border: 1px solid #dbdbdb;
}

.DateInput_input {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.1876em;
  letter-spacing: 0.00938em;
  color: black;
  background-color: #fff;
  width: 100%;
  padding: 8px 11px 9px 9px;
  box-sizing: border-box;
  border-radius: 5px;
}

/* SingleDatePickerInput */

/* .DateRangePicker_picker__portal {
  zIndex
} */

.SingleDatePicker_picker__portal {
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  /* z-index: 9999; */
  border: 2px solid #000;
  /* border: 2px solid #000; */
}

.DateRangePicker_picker__portal {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  /* z-index: 9999; */
  /* border: 2px solid #000; */
  border: 2px solid #000;
}

.DateRangePickerInput_clearDates_svg {
  display: flex;
  fill: #82888a;
  height: 12px;
  width: 15px;
}

.DateInput {
  margin: 0;
  padding: 0;
  background: #fff;
  position: relative;
  display: inline-block;
  width: 115px;
  vertical-align: middle;
  border-radius: 5px;
}

.DateRangePickerInput__showClearDates {
  padding-right: 40px;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #3f51b5;
  border: 1px double #3f51b5;
  color: #fff;
  border-radius: 5px;
}

.CalendarDay__selected_span {
  background: #77b5ec;
  border: 1px double #77b5ec;
  color: #fff;
}

.SingleDatePickerInput__withBorder {
  border-radius: 5px;
  border: 1px solid #dbdbdb;
}
