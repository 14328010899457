* {
  box-sizing: border-box;
}

body,
html {
  height: 100vh;
  width: 100vw;
  max-width: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  font-size: 12px;
}

.App {
  text-align: center;
  font-family: 'Open Sans' !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.closed-cell {
  font-size: 10px;
}
/* 
main > content {
  padding-left: 50px;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  max-width: inherit;
  max-height: inherit;
} */

.content {
  padding-left: 50px;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  max-width: inherit;
  max-height: inherit;
}

.NavBar {
  width: 50px;
  transition: width 0.3s;
  overflow: hidden;
  white-space: nowrap;
  position: absolute;
  background: #fff;
  z-index: 99999;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  /* height: calc(100% - 44px); */
}
.NavBar .MuiButtonBase-root {
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.NavBar.navBarOpen a > div.MuiListItemText-root {
  opacity: 1;
}
.NavBar a > div.MuiListItemText-root {
  transition: opacity 0.2s;
  opacity: 0;
}

.NavBar.navBarOpen {
  /* width: 250px; */
  width: 200px;
  box-shadow: 10px 10px 100px #ccc;
}

* {
  box-sizing: border-box;
}

.flex {
  display: flex;
}

.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
}

.grow {
  flex: 1;
}

.half-column {
  width: 50%;
}

.center-page {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.justify-center {
  display: flex;
  /* flex-direction: column-reverse; */
  justify-content: center;
}

.align-center {
  align-items: center;
}

.shrink {
  flex: 0 1;
}

iframe {
  border: none;
}

.green-button {
  background-color: green !important;
}

.red {
  color: red;
}

.purple {
  color: #594492;
}

.green {
  color: green;
}

.orange {
  color: orange;
}

.width-40-percent {
  width: 80%;
}

button.spaced {
  margin: 30px;
}

button.spaced-small {
  margin: 8px;
}

button.spaced-extra-small {
  margin: 5px;
}

.button-normal {
  background-color: #414141 !important;
}

.bold {
  text-emphasis: bold;
}
.get-report-button {
  margin: 5px 8px;
}

.json-scroll {
  overflow: scroll;
  max-height: 50vh;
}

.pre-user {
  /* overflow: scroll; */
  max-height: 50vh;
}

button.dense {
  padding: 4px 10px !important;
}

/* .loading-bar-light {
  color: #414141 !important;
  background-color: #414141 !important;
  background: #414141 !important;
} */

.loading-bar-dark {
  /* color: #414141 !important; */
  background-color: #d0d3d4 !important;
}

.spaced-small-left {
  margin-left: 8px;
}

.spaced-small-top {
  margin-top: 8px;
}

.spaced-small-right {
  margin-right: 8px;
}

.spaced-extra-small-left {
  margin-left: 5px;
}

.spaced-extra-small-bottom {
  margin-bottom: 5px;
}

.spaced-extra-small-top-bottom {
  margin-top: 6px;
  margin-bottom: 6px;
}

.spaced-extra-small {
  margin: 5px;
}

.spaced-extra-small-top {
  margin-top: 5px;
}

.spaced-small-top-bottom {
  margin-top: 8px;
  margin-bottom: 8px;
}

.spaced-small-bottom {
  margin-bottom: 8px;
}

.Scheduler-Top-Select {
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 8px;
  margin-bottom: 0px;
}

.services-container {
  margin-top: 5px;
}

/* .Scheduler-Top-Select {
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 8px;  
  margin-bottom: 16px;
} */

.spaced-left-xs {
  margin-left: 3px;
}

.spaced-right-xs {
  margin-right: 3px;
}

.spaced-right-s {
  margin-right: 8px;
}

.spaced-small {
  margin: 8px;
}

.spaced-medium-left-right {
  margin-left: 16px;
  margin-right: 16px;
}

.spaced-medium-top {
  margin-top: 16px;
}

.spaced-large-top {
  margin: 16px;
}

.service-is {
  margin-top: 23px;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 8px;
}

.spaced-medium {
  margin: 16px;
}

.spaced-large {
  margin: 32px;
}

.spaced-left-extra-small {
  margin-left: 5px;
}

.spaced-left-small {
  margin-left: 8px;
}

.app-bar-page-title {
  margin-top: 8px;
  margin-left: 2%;
  margin-bottom: 8px;
}

.space-around {
  justify-content: space-around;
}

.user-name {
  margin: 5px;
}

.AppBar {
  background: #000;
  color: #fff;
  padding: 2px 1px;
}

.AppBar .avatar {
  border-radius: 50%;
  height: 24px;
  width: 24px;
  margin: 3px 3px;
}

.spaced-medium-top-bottom {
  margin-top: 2px;
  margin-bottom: 2px;
}

.spaced-small-top-bottom {
  margin-top: 8px;
  margin-bottom: 8px;
}

.spaced-medium-top {
  margin-top: 16px;
}

.avatar {
  border-radius: 50%;
  margin: 5px;
}

.avatar-large {
  border-radius: 50%;
  margin: 10px;
  width: 300px;
  height: 300px;
}

.Homepage {
  color: #000;
  background: #fff;
  padding: 15px 10px;
}

.Admin {
  color: #000;
  background: #fff;
  /* padding: 15px 10px; */
}

.Scheduled-Reports {
  color: #000;
  background: #fff;
}

.Techtime {
  color: #000;
  background: #fff;
  padding: 15px 10px;
}

.date {
  margin: 5px 5px;
}

.single-date {
  margin: 5px 5px;
}

.date-range {
  margin: 5px 5px;
}

.auto-complete {
  margin: 5px 5px;
}

.text-field {
  margin: 5px 5px;
}

.logo {
  height: 20vmin;
  pointer-events: none;

  animation-name: moveInRight;
  animation-duration: 1s;
  animation-timing-function: ease-out;
}

.date-selector {
  margin: 5px 5px;
}

.selector {
  margin: 5px 5px;
}

.date-picker {
  padding-left: 5px;
}

.date-picker-schedule-item {
  padding-left: 5px;
  padding-top: 5px;
}

.date-picker-scheduler {
  margin: 8px;
  margin-top: 17px;
}

.time-zone-scheduler {
  margin: 8px;
  margin-top: 17px;
}

.time-picker-scheduler {
  margin-left: 8px;
  margin-right: 8px;
}

.body {
  height: 100%;
  width: 100%;
  flex-direction: column;
}

.wrapper-min-height {
  min-height: 100%;
}

.wrap {
  flex-wrap: wrap;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ffffff;
  /* min-height: 100vh; */
  margin: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.Page-header {
  background-color: #ffffff;
  /* min-height: 100vh; */
  margin: 30px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

.Iframe-container {
  margin: 30px;
}

.Iframe-Paramater-container {
  display: flex;
  margin: 30px;
  flex-direction: row;
  align-items: flex-start;
}

.loading-circle {
  margin: 40px;
}

.flex-end {
  flex-direction: row-reverse;
  display: flex;
}

.space-between {
  justify-content: space-between;
  display: flex;
}

.Profile {
  color: #000;
  background: #fff;
  padding: 15px 10px;
}

.Dashboard {
  color: #000;
  background: #fff;
  padding: 15px 10px;
}

.Login {
  color: #000;
  background: #fff;
  padding: 15px 10px;
}

.row-reverse {
  display: flex;
  /* flex-direction: row-reverse; */
}

.user-profile {
  color: black;
  background: #f8f8f8;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  /* max-width: 700px; */
  min-width: 500px;
  /* width: 700px; */
  /* padding: 25px; */
  /* text-align: center; */
}

.client-editor {
  color: black;
  background: #f8f8f8;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  /* max-width: 500px;
  width: 5000px; */
  min-width: 40vw;
  max-width: 80vw;
  /* padding: 25px; */
  /* text-align: center; */
}

.report-creator {
  color: black;
  background: #f8f8f8;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  /* max-width: 500px;
  width: 5000px; */
  min-width: 40vw;
  max-width: 80vw;
  /* padding: 25px; */
  /* text-align: center; */
}

.schedule-creator {
  color: black;
  background: #f8f8f8;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  /* max-width: 500px;
  width: 5000px; */
  min-width: 40vw;
  max-width: 80vw;
  /* padding: 25px; */
  /* text-align: center; */
}

.test-playground {
  color: black;
  background: #f8f8f8;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  /* max-width: 500px;
  width: 5000px; */
  min-width: 40vw;
  max-width: 80vw;
  /* padding: 25px; */
  /* text-align: center; */
}

.user-admin {
  color: black;
  background: #f8f8f8;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  /* max-width: 500px;
  width: 5000px; */
  min-width: 40vw;
  max-width: 80vw;
  /* padding: 25px; */
  /* text-align: center; */
}

.middle-spacer {
  color: black;
  background: #000000;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
}

.reauthenticate {
  color: black;
  background: #f8f8f8;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 500px;
  /* padding: 25px; */
  /* text-align: center; */
}

.card {
  color: black;
  background: #f8f8f8;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 700px;
  width: 700px;
}

.card-min-height {
  color: black;
  background: #f8f8f8;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 700px;
  width: 700px;
  max-height: 300px;
  height: 300px;
}

.top-level {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.Card-Schedule {
  min-width: 70vw;
  max-width: 70vw;
  width: 70vw;
  overflow: 'scroll';
  /* min-height: 75vw; */
  /* max-height: 75vw;
  width: 75vw; */
  /* max-height: 600;
  height: 650; */
}

.card-wide {
  color: black;
  background: #f8f8f8;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  /* max-width: inherit; */
  /* max-height: inhertit; */
  /* max-width: 95vw;
  width: 95vw; */
}

/* .scroll {
  overflow: auto;
} */

.card-selected {
  color: black;
  background: #dbd8d8;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
  max-width: 700px;
  width: 700px;
}

.white {
  background: white;
}

.border-box {
  border-width: 10px;
}

.hover-effect:hover {
  opacity: 0.7;
  cursor: pointer;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiAutocomplete-root.multiple .MuiInputBase-root {
  min-height: 40px;
}

.MuiAutocomplete-root.multiple .MuiAutocomplete-tagSizeSmall {
  max-width: calc(100% - 30px);
}
.MuiAutocomplete-root.multiple .MuiAutocomplete-input {
  display: none;
}
.MuiAutocomplete-root.multiple[aria-expanded='true'] .MuiAutocomplete-input,
.MuiAutocomplete-root.multiple:hover .MuiAutocomplete-input {
  display: block;
}

tr:nth-child(odd) {
  background: #ddd;
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(90px);
  }
  50% {
    transform: translateX(-15px);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}
